<template>
  <section>
    <search-bar-register
      class="mt-2 mb-2 sm:mb-3"
      :title="$t('sectionNcm.searchNcm')"
      @input="filter = $event"
    />

    <b-table
      responsive="sm"
      :fields="fields"
      primary-key="Ncm"
      :items="items"
      :filter="filter"
      show-empty
      empty-text="Não existem NCM cadastrados"
      :empty-filtered-text="`NCM não encontrado`"
    >
      <template #cell(Ncm)="data">
        {{ Indice + (data.index + 1) }}
      </template>
      <template #cell(ações)="data">
        <div class="acoes">
          <div>
            <button @click="RedirectUrl(`view/${data.item.id}`)">
              <EyeIcon class="w-5 mr-2 icon__actions" />
            </button>
          </div>
          <div>
            <button @click="RedirectUrl(`edit/${data.item.id}`)">
              <EditIcon class="w-4 mr-2 icon__actions" />
            </button>
          </div>
          <div>
            <button @click="deletarNcm(data.item.id)">
              <DeleteIcon class="w-5 mr-2 icon__actions" />
            </button>
          </div>
        </div>
      </template>
    </b-table>

    <footer class="mt-2 flex items-center">
      <Pagination
        :totalItems="totalItems"
        :itemsPerPage="itemsPerPage"
        @input="ListaNcm"
        text="NCM"
      />
    </footer>
    <Modal
      v-if="modalIsOpen"
      :modalIsOpen="modalIsOpen"
      :closeModal="closeModal"
      :buttonOptions="true"
      :confirmarDelete="confirmarDelete"
      :DeleteId="deletar"
      :modalText="$t('commons.modalTextDelete')"
    />
    <Modal
      v-if="modalDeleted"
      :modalIsOpen="modalDeleted"
      :closeModal="closeModal"
      :modalText="$t('commons.modalTextDeleted')"
    />
  </section>
</template>

<script>
import { BTable } from "bootstrap-vue";

import SearchBarRegister from "@core/components/register/SearchBarRegister.vue";
import PageOperationsEnum from "@core/helpers/constants/pagesOperation";
import Modal from "@core/components/commons/modal/Modal.vue";
import Pagination from "@core/components/commons/pagination/Pagination.vue";

import EyeIcon from "@/assets/img/eye.svg";
import EditIcon from "@/assets/img/editar.svg";
import DeleteIcon from "@/assets/img/delete.svg";

export default {
  name: "ListUsers",
  components: {
    SearchBarRegister,
    Modal,
    Pagination,
    BTable,
    EyeIcon,
    EditIcon,
    DeleteIcon,
  },
  data() {
    return {
      modalIsOpen: false,
      modalDeleted: false,
      totalItems: 0,
      itemsPerPage: 10,
      PageOperationsEnum,
      Indice: 0,
      filter: null,
      deletar: null,
      fields: [
        { key: "codigoNcm", label: "NCM", sortable: true },
        { key: "descricao", label: "Descrição", sortable: true },
        { key: "ações" },
      ],
      items: [],
    };
  },
  created() {
    this.ListaNcm();
  },
  methods: {
    closeModal(event) {
      if (event.target === event.currentTarget) {
        this.modalIsOpen = false;
        this.modalDeleted = false;
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    ListaNcm(page = 1) {
      this.$http({
        url: `/ncm/obter-grid/${page}`,
        method: "Get",
      }).then((response) => {
        this.items = response.data.itens;
        this.totalItems = response.data.total;
        this.itemsPerPage = response.data.itensPorPagina;
        var IndiceFinal = page * response.data.itensPorPagina;
        this.Indice = IndiceFinal - response.data.itensPorPagina;
      });
    },
    RedirectUrl(val) {
      this.$router.push(`ncm/${val}`);
    },
    deletarNcm(id) {
      this.modalIsOpen = true;
      this.deletar = id;
    },
    confirmarDelete(id) {
      this.$http({
        url: `/ncm/remover/${id}`,
        method: "Post",
      }).then(() => {
        this.modalIsOpen = false;
        this.modalDeleted = true;
        this.$router.go();
      });
    },
  },
};
</script>
<style lang="scss">
.acoes {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
}
</style>
