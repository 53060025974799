import HeaderRegister from "@core/components/register/HeaderRegister.vue";
import ListNcm from "@/components/ncm/ListNcm.vue";

export default {
  components: {
    HeaderRegister,
    ListNcm,
  },
  data() {
    return {
      previewButtonIsVisible: true,
    };
  },
};
